<template>
 <v-card  min-height="500px" flat>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      @click="clickedDsp()"
      viewBox="0 0 294.7 349.2"
      id="dispenser"
      class="despenser"
      :height="$parent.maxHeight "
    >
      <line x1="25.3" y1="276" x2="168.4" y2="276" class="b" />
      <path
        d="m261.4 68.1c0 0.6-0.5 1-1 1H228.2c-0.6 0-1-0.4-1-1V39.5c0-0.6 0.4-1 1-1h32.2c0.5 0 1 0.5 1 1z"
        stroke="#000"
        :fill="setColor(pumpData.tamper)"
      />
      <rect x="225.7" y="23.8" width="69" height="23.6" class="c" />
      <text id="volt" font-size="12.3479" x="225.7" y="32.6" class="d">{{pumpData.volts}}V</text>
      <rect x="6.7" y="280.4" width="179.9" height="61.7" class="b" />
      <path
        id="bottombox"
        d="m176.7 330.5c0 1.5-1.8 2.8-4 2.8H20.7c-2.2 0-4-1.3-4-2.8v-38.5c0-1.5 1.8-2.8 4-2.8H172.7c2.2 0 4 1.3 4 2.8z"
        :fill="setColor(pumpData.bottombox)"
      />
      <rect x="22.4" y="293.6" width="150.2" height="33.5" class="c" />
      <text
        font-size="17.6399"
        x="22.4"
        y="306.1"
        class="e"
      >V: {{pumpData.vehicle ? pumpData.vehicle.substr(0,15) : ''}}</text>
      <rect x="22.4" y="315.6" width="146.4" height="33.5" class="c" />
      <text
        font-size="17.6399"
        x="22.4"
        y="328.2"
        class="e"
      >D: {{pumpData.driver ? pumpData.driver.substr(0,15) : ''}}</text>
      <rect x="25.3" y="142.3" width="143.1" height="133.1" fill="#6d6e71" stroke="#fff" />
      <rect x="51.9" y="168.8" width="90" height="80" class="f" />
      <path
        id="panel"
        d="m186.3 120.2c0 6.9-5.8 12.8-12.7 12.8H20.1c-7 0-12.8-5.9-12.8-12.8V17.9c0-7.1 5.8-12.7 12.8-12.7h153.4c7 0 12.7 5.7 12.7 12.7z"
        :fill="setStatus(pumpData.status)"
      />
      <path
        d="m193.2 124.4c0 7.7-6.3 13.7-13.8 13.7H14.2C6.7 138.1 0.5 132.1 0.5 124.4V14.2C0.5 6.8 6.7 0.5 14.2 0.5h165.2c7.5 0 13.8 6.3 13.8 13.7v110.2z"
        fill="none"
        stroke="#000"
      />
      <path
        d="m151.8 49.6c0 1.4-1.4 2.7-3.2 2.7H29.7c-1.7 0-3.2-1.3-3.2-2.7V13.8c0-1.4 1.5-2.6 3.2-2.6h118.9c1.8 0 3.2 1.2 3.2 2.6z"
        class="f"
      />
      <path
        d="m151.8 88.3c0 1.2-1.1 2.1-2.6 2.1H54c-1.3 0-2.4-0.9-2.4-2.1V61.5c0-0.9 1.1-2.1 2.4-2.1h95.2c1.5 0 2.6 1.2 2.6 2.1z"
        class="f"
      />
      <rect x="155.3" y="20.6" width="22.9" height="50.2" class="c" />
      <text font-size="33.9785" x="155.3" y="44.7" class="g">L</text>
      <rect x="130.6" y="18.4" width="22.5" height="38" class="c" />
      <text
        font-size="33.9785"
        x="148.6"
        y="42.5"
        text-anchor="end"
        class="g"
      >{{pumpData.quantity}}</text>
      <rect x="135.3" y="65.2" width="15.1" height="38.2" class="c" />
      <text font-size="24.2704" x="148.6" y="82.4" text-anchor="end" class="e">{{pumpData.sale}}</text>
      <path
        d="m151.8 125.1c0 1.2-0.7 2-1.7 2H88.3c-0.8 0-1.6-0.8-1.6-2V99.2c0-0.9 0.7-2 1.6-2h61.9c1 0 1.7 1.1 1.7 2z"
        class="f"
      />
      <rect x="135.3" y="104" width="20" height="38.2" class="c" />
      <text font-size="24.2704" x="148.6" y="121.3" text-anchor="end" class="e">{{pumpData.price}}</text>
      <rect x="25" y="63.8" width="50.2" height="18" class="c" />
      <g :fill="pumpData.virtual_inuse ? setHose(4) : setHose(pumpData.nozzle)">
        <path
          d="m197.6 65.4 20 27.9c-18.4 18.2-8 41.2 14.2 37.6l0.2 9.5-7.3 30.9v102.4c-3.9 10.3-17.7 10-20.2-1.3l0.2-85.2c0-11.9-9.5-20.1-18.9-21.8l-14.3 0 0 11.6 11.1 0c7.2-0.3 12.5 6.1 12.4 14.4l-0.3 84c3 21.2 31.5 23.5 40.3 4.5l-0.1-108.6 9.2-31-0.1-49-44.9-42-1.6 16.1"
        />
        <path d="m222 101.6 8.6 11.5 0.2 7.9c-11.3 5.8-20-11.6-8.8-19.4" fill="#ffffff" />
        <polygon
          points="512.9 235.3 500.6 248.1 504.3 251.8 516.7 239 "
          transform="translate(-282.062 -132.007)"
        />
      </g>
      <rect x="74.3" y="177.8" width="59.2" height="68.1" class="c" />
      <text x="74.3" y="237.9" font-family="Lato" font-size="80px">{{pumpData.flag}}</text>
      <rect x="22.4" y="73.7" width="37.9" height="55.9" class="c" />
      <text id="did" x="15.5" y="100.6" font-family="Lato" font-size="40px">{{pumpData.id}}</text>
      <rect x="154.4" y="108" width="34.3" height="11.8" class="c" />
      <text id="cena" font-size="10.5839" x="154.4" y="115.5" class="d">CZK&#x2F;L</text>
      <rect x="155.3" y="71.4" width="34.3" height="11.8" class="c" />
      <text id="curr" font-size="10.5839" x="155.3" y="78.9" class="d">CZK</text>
      <rect x="227" y="90.3" width="15.1" height="38.2" class="c" />
      <text id="nid" font-size="24.2704" x="227" y="107.6" class="e">{{pumpData.nozzle_id}}</text>
      <rect x="14" y="108" width="69.6" height="38.2" class="c" />
      <text id="product" font-size="17.6399" x="17.8" y="125.6" class="e">{{pumpData.product}}</text>
    </svg>
 </v-card>
</template>
<script>
// import store from '../store'
export default {
  name: 'Dispenser',
  props: ['pumpData'],
  methods: {
    setStatus (s) {
      switch (s) {
        case 1:
          return '#809fff'
        case 2:
          return '#79d279'
        case 3:
          return '#ffc266'
        case 4:
          return '#ff4d4d'
        default:
          return 'grey'
      }
    },
    setHose (s) {
      switch (s) {
        case 0:
          return 'red'
        case 1:
          return 'green'
        case 4:
          return 'orange'
        default:
          return 'red'
      }
    },
    setColor (s) {
      switch (s) {
        case 0:
          return '#808080'
        case 1:
          return '#ff4d4d'
        case 2:
          return '#79d279'
        case 3:
          return '#ffc266'
        case 4:
          return '#809fff'
        default:
          return 'grey'
      }
    },
    clickedDsp () {
      if (this.pumpData.status > 1) {
        this.$emit('releasedsp', this.pumpData.id)
      } else {
        this.$emit('clickeddsp', this.pumpData.id)
      }
    }
  },
  computed: {
    // pumpData () {
    //   return this.$store.getters.getPumpPropById(this.did)
    // }
  }
}

</script>
 <style scoped>
#dispenser {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: auto;
}


#dispenser:hover {
  background-color: #e0e1e2;
}
.a {
  fill: #00a651;
  stroke-width: 0.5;
  stroke: #fff;
}
.b {
  fill: #231f20;
  stroke: #fff;
}
.c {
  fill: none;
}
.d {
  font-family: Lato;
  font-size: 10px;
}
.e {
  font-family: Lato;
  font-size: 18px;
}
.f {
  fill: #fff;
}
.g {
  font-family: Lato;
  font-size: 30px;
}
.g {
  font-family: Lato;
  font-size: 30px;
}
</style>
