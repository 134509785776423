<template>
  <v-container>
    <v-card   flat >
      <v-row justify="center" >
      <v-col class="mb-4 white--text"  justify="left" justify-md="center" style="background-color: black;  border-radius: 0.5rem;" >
        <v-row >
          <v-col cols="6" md="4"  class="d-flex justify-end align-content-end">
            
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                  class="mb-6"
                  v-bind="attrs"
                  v-on="on" 
                  large
                  :color= "isConnected ? 'green': 'red'">{{ isConnected ? "mdi-lan-connect" : "mdi-lan-disconnect"}}
                  </v-icon>
                </template>
                <span>{{ $t('Pump is Connected') }}</span>
              </v-tooltip> 
            
          </v-col>
        <v-col cols="8" md="4" >
          <v-autocomplete
            outlined
            dense
            dark
            v-model="tag"
            :items="entries"
            :loading="isLoading"
            :search-input.sync="search"
            color="white"
            
            hide-selected
            item-text="name"
            item-value="user_data1"
            :label="$t('Select vehicle')"
            :placeholder="$t('Start typing to Search')"
            prepend-icon="mdi-database-search"
            clearable
            @click:clear="() => {entries = []}"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4">
        
              <v-btn
                class="mx-2 vinland-fab"
                fab
                dark
                small

                @click="registerKey"
                outlined
              >
                <v-icon 
                  class="my-2"
                  
                  :color= "getAuthKey ? 'green': 'red'"
                >{{ getAuthKey ? "mdi-lock-open" : "mdi-lock"}}
                </v-icon>
              </v-btn>
           
            <span class="text-caption mx-2">{{ getAuthKey ? $t('Authorized') : $t('Unauthorized') }}</span>
          
        </v-col>
            <!-- <v-text-field
            outlined
            dense
            dark
            v-model="tag"
            :label="$t('Tag')"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :type="show ? 'text' : 'password'"
            
            >
            </v-text-field>   -->
     
          <!-- <v-col cols="4" md="2" >
            <v-btn color="vinland" @click="registerKey(tag)">
              <v-icon color="white">mdi-send</v-icon>
            </v-btn>
          </v-col> -->
        </v-row>
      </v-col>
      </v-row>
    </v-card>
      <v-row justify="center" class="my-auto">
      <v-col  v-for="n in pumpProps" :key="n.id" cols="12" md="6" >
        <v-card class="pa-auto">
       
          <dispenser  
          :pumpData="n" 
          @clickeddsp="id => {sendRequest(id)}" 
          @releasedsp="id => {sendRelease(id)}" 
          
          />
        
        </v-card>
      </v-col>
     </v-row>
  
  </v-container>
 
  
</template>

<script>

import Dispenser from '../components/Dispenser.vue'
export default{
  name: 'Home',
  components: {
    Dispenser
  },
  data () {
    return {
      tag: '',
      size: null,
      show: false,
      entries: [],
      isLoading: false,
      search: null,
    }
  },
  methods: {
    async setkeep () {
      console.log('SPOJENÍ S ŘÍDÍCÍ JEDNOTKOU NAVÁZÁNO')
      this.keepalive = setInterval(() => { this.$socket.sendObj({ 'keep': true, 'key': 'asdf' }) }, 15000)
      const res = await this.$http.get("/system")
      this.$store.commit('SET_STATION', res.data)
    },
    sendRequest (i) {
      
      this.$socket.sendObj({action: 'allowdispenser', key: this.$auth.token(), id: i.toString(), tag: this.getAuthKey})
    },
    sendRelease (i) {
      this.$socket.sendObj({action: 'releasedispenser', key: this.$auth.token(), id: i.toString()})
    },
    async registerKey () {
      if (this.getAuthKey) {
        this.$store.commit('PUMP_AUTH_KEY', '')
        this.tag = ''
        return
      }
      if (this.tag) {
        let res = null
        try {
          // res = await this.$http.get((`/vehicle/${this.tag}`))
          this.responseString(this.tag)
        } catch(e) {
         this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        }
      } else {
        this.$store.commit('PUMP_AUTH_KEY', '')
      }
    },
    responseString (str) {
      if (str) {
        this.$store.commit('PUMP_AUTH_KEY', str)
        // this.tag = ''
      } else {
        alert(this.$t('string_is_empty'))
      }
    },
    errlog (e) {
      console.log(e)
    },
    connect () {
      console.log('clicked')
      if (!this.isConnected) {
        this.$socket.reconnectionAttempts = 0
        this.$socket.reconnect()
      }
    },
    getColumnClass (n) {
      let o = {
        'ui': true,
        'stackable': true
      }
      if (n >= 3) {
        return { 'ui': true,
          'three': true,
          'column': true,
          'grid': true }
      }
      let i = n % 3
      switch (i) {
        case 1:
          o.one = true
          break
        case 2:
          o.two = true
          break
        case 3:
          o.three = true
          break
        default:
          o.three = true
      }
      o.column = true
      o.grid = true
      return o
    }
  },
  created () {
    this.setkeep()
  },
  destroy () {
    clearInterval(this.keepalive)
  },
  computed: {
    colClass () {
      return this.getColumnClass(this.$store.getters.getPumpCounter())
    },
    pumpsCount () { return this.$store.getters.getPumpCounter() },
    getAuthKey () { return this.$store.getters.getAuthKey() },
    isConnected () { return this.$store.getters.getIsConnected() },
    pumpProps () {
      return this.$store.state.pumps.statuses
    }
  },
  watch: {
      async search (val) {
       
        // Items have already been loaded
        //if (this.entries.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        try {
          const res = await this.$http.get("/vehicle", { params: { authorizable: val}})
          this.entries = res.data
          this.isLoading = false
        } catch (e) {
          console.log(e)
        } finally {
          this.isLoading = false
        }

      }
  },
  mounted () {
    this.$nextTick(() => {
      this.size = this.$el.clientHeight
    })
  },
  created () {
    this.search = ""
  }
}
</script>
<style scoped >
.vinland {
  background-color: #196619 !important;
}
.vinland-fab {
  border-width: 3px;
  border-color: green !important;
}


</style>